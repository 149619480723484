/* website: 3540-yorkdale-dufferin-mazda
 * created at 2021-10-19 16:30 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/mazda.scss";
@import "../utils/icons.scss";

@include breakpoint(mobile){
    .widget-sr {
        &.dealer__yorkdale-dufferin-mazda {
        .LoginBanner{
            padding: 0px!important;
        }
        .LoginBanner .LoginBannerWrapper {
          height: 34px;
          max-height: 34px;
          .login-content {
            display: flex;
            padding-right: 0;
            #button-login,
            #button-register {
              font-size: 12px !important;
              .label{
                font-size: 12px !important;
              }
            }
  
            #button-login.btn-login {
              margin-left: 10px;
            }
  
            #button-register.btn-register {
              margin-left: 0;
              min-width: 110px !important;
              padding: 5px !important;
            }
          }
        }
      }
    }
  }